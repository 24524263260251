import builder from '@builder.io/react';
import { FeatureFlag } from '../models/BuilderFeatureFlag';
import { BuilderServiceContentApi } from '../models/BuilderContentAPI';

class BuilderService {
  public static async getFeatureFlagByTitle(title: string): Promise<boolean> {
    const featureFlag = await builder.get('feature-flag', {
      query: {
        data: {
          title,
        },
      },
    });
    if (featureFlag && featureFlag.data && featureFlag.data.title === title) {
      return featureFlag.data.value as boolean;
    } else return false;
  }
  public static async getAllFeatureFlags(): Promise<FeatureFlag[]> {
    const featureFlagscontent = await builder.getAll('feature-flag');
    const featureFlags: FeatureFlag[] = featureFlagscontent.map(
      (featureFlag) => ({
        title: featureFlag.data?.title,
        value: featureFlag.data?.value as boolean,
        targetUserIds: featureFlag.data?.targetUserIds,
      }),
    );
    return featureFlags;
  }

  public static getFeatureFlagValue(
    featureFlags: FeatureFlag[],
    title: string,
    userId: number | undefined,
  ): boolean {
    const featureFlag = featureFlags.find((flag) => flag.title === title);
    if (!featureFlag) {
      return false;
    }
    if (userId && featureFlag.targetUserIds) {
      const targetUserIds = featureFlag.targetUserIds.split(',');
      if (targetUserIds.includes(userId.toString())) {
        return featureFlag.value; //
      }
      return false; // if user is not in targetUserIds
    }
    return featureFlag.value; // if user is not provided or targetUserIds is not provided
  }

  public static fetchBuilderContentAlive = async (
    modelName: string,
  ): Promise<BuilderServiceContentApi | undefined> => {
    const modelContents = await builder.getAll(modelName);
    const result: BuilderServiceContentApi = {};
    const allComponents = modelContents.filter((v) => v?.data?.identifier);
    allComponents.forEach((component) => {
      if (component?.data?.identifier) {
        result[component.data.identifier] = component.published === 'published';
      }
    });
    return result;
  };
}

export default BuilderService;
