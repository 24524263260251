import { useEffect, useState } from 'react';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { useSearchParams } from 'next/navigation';
import { current_user_result } from '../api';

export interface AdminUserSearchParamInterface {
  user: current_user_result | undefined;
  userIdQueryParam: string | undefined;
  userId: string | undefined;
  usernameValue: string | undefined;
  isAdmin: boolean;
  hasUser: boolean;
  loading: boolean;
}

export function useAdminUserSearchParam(): AdminUserSearchParamInterface {
  const { user, loading } = useAuthHook();
  const searchParams = useSearchParams();
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userIdQueryParam, setUserIdQueryParam] = useState<string>('');
  const [usernameValue, setUserNameValue] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasUser, setHasUser] = useState<boolean>(false);

  const userIdParam = searchParams.get('userId') || undefined;
  const usernameParam = searchParams.get('username') || undefined;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      return;
    }

    const userAdmin = user?.['is-admin'];
    if (userAdmin) {
      if (userIdParam) {
        setUserId(userIdParam);
        setUserIdQueryParam(`?userId=${userIdParam}`);
      }
      if (usernameParam) {
        setUserNameValue(usernameParam);
      }
      setIsAdmin(true);
    } else {
      setUserId(undefined);
      setUserIdQueryParam('');
      setUserNameValue('');
      setIsAdmin(false);
    }
    setHasUser(true);
  }, [loading, searchParams]);

  return {
    user,
    userIdQueryParam,
    userId,
    usernameValue,
    isAdmin,
    hasUser,
    loading,
  };
}
