'use client';
import { useState, useEffect } from 'react';
import { UserContext } from '../lib/context';
import { AuthStore, useAuthHook } from '../lib/store/auth.store';
import { ExchangeRateStore } from '../lib/store/exchange-rate';
import { Currency } from '../types';
import { defaultUserState } from '../lib/context/constants';
import { initializeFacebookSdk } from './(auth)/actions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from '../config';
import { getCurrencyUsingLocale } from '../lib';
import FeatureFlagContext from '../lib/context/provider/FeatureFlagContext';
import { FeatureFlag } from '../lib/api/v4/client/models/BuilderFeatureFlag';
import BuilderService from '../lib/api/v4/client/services/BuilderService';
export default function RootLayoutWithContext({
  children,
}: {
  children: React.ReactNode;
}) {
  const [userId, setUserId] = useState<number | undefined>();
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [profileImageSrc, setProfileImageSrc] = useState<string>('');
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  const { user, loading } = useAuthHook();

  useEffect(() => {
    // get current user
    AuthStore.refreshCurrentUser();

    //initialise facebook sdk
    setTimeout(() => initializeFacebookSdk(), 500);
    BuilderService.getAllFeatureFlags().then((flags) => {
      setFeatureFlags(flags);
    });
  }, []);

  useEffect(() => {
    if (!currency) return;

    ExchangeRateStore.triggerUpdate(currency);
  }, [currency]);

  useEffect(() => {
    if (loading) return;
    // if a user has a currency set, use it
    if (user && user['user-currency']) {
      setCurrency(user['user-currency'] as Currency);
      localStorage.setItem('userPreferredCurrency', user['user-currency']);
      return;
    }

    // if no currency is set, use the user's preferred currency if it exists
    const userPreferredCurrency = localStorage.getItem('userPreferredCurrency');
    if (userPreferredCurrency) {
      console.debug(
        `Currency: detected user preferred currency of "${userPreferredCurrency}"`,
      );
      setCurrency(userPreferredCurrency as Currency);
      return;
    }

    // if both the user and local storage have no currency set, use the user's locale to determine the currency
    if (navigator.language) {
      const locale = navigator.language.toLowerCase();
      const currency = getCurrencyUsingLocale(locale);
      setCurrency(currency);
      return;
    }

    // if all else fails, default to USD
    console.debug(
      `Currency: could not ascertain currency, so falling back to "USD"`,
    );
    setCurrency('USD');
  }, [loading]);

  useEffect(() => {
    if (!user) return;

    // need to rename the keys to match the user context
    const {
      'user-id': userId,
      email,
      'user-thumbnail-url': profileImageSrc,
      username,
      'first-name': firstName,
      'user-name': displayName,
    } = user;

    console.log('Loaded user info OK:', {
      userId,
      email,
      profileImageSrc,
      username,
      firstName,
      displayName,
    });
    setUserId(userId);
    setDisplayName(displayName || defaultUserState.displayName);
    setEmail(email);
    setFirstName(firstName || '');
    setUsername(username);
    setProfileImageSrc(profileImageSrc);
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFeatureFlag = (title: string): boolean => {
    return BuilderService.getFeatureFlagValue(featureFlags, title, userId);
  };
  return (
    <UserContext.Provider
      value={{
        userId,
        email,
        firstName,
        username,
        displayName,
        profileImageSrc,
        currency,
        setCurrency,
      }}
    >
      <FeatureFlagContext.Provider value={{ featureFlags, getFeatureFlag }}>
        <GoogleOAuthProvider clientId={config().googleClientId}>
          {children}
        </GoogleOAuthProvider>
      </FeatureFlagContext.Provider>
    </UserContext.Provider>
  );
}
