import config from '@/src/config';
import { AuthStore } from '@/src/lib/store/auth.store';
import { GoogleUserDataResponse } from './types';

export const initializeFacebookSdk = () => {
  window.FB.init({
    appId: config().facebookAppId,
    status: true,
    cookie: true,
    xfbml: true,
    version: 'v14.0',
  });
};

export const facebookLoginStatus = (): Promise<fb.StatusResponse> => {
  return new Promise((resolve) => {
    window.FB.getLoginStatus((response) => {
      resolve(response);
    });
  });
};

export const facebookLogin = (): Promise<fb.StatusResponse> => {
  return new Promise((resolve) => {
    window.FB.login((response) => {
      resolve(response);
    });
  });
};

export const getFacebookProfile = (): Promise<fb.User> => {
  return new Promise<fb.User>((resolve) => {
    window.FB.api('/me?fields=email,first_name,last_name,name', (response) => {
      resolve(response);
    });
  });
};

export const getFacebookProfilePicture = (): Promise<string | undefined> => {
  return new Promise((resolve) => {
    window.FB.api(
      `/me/picture?height=500&width=500&redirect=false`,
      (response: any) => {
        resolve(response.data.is_silhouette ? undefined : response.data.url);
      },
    );
  });
};

export const facebookLogout = async (): Promise<void> => {
  const loggedInStatus = await facebookLoginStatus();
  const { status } = loggedInStatus;
  if (status !== 'connected') {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    window.FB.logout(() => {
      resolve();
    });
  });
};

export const getGoogleProfile = async (
  accessToken: string,
): Promise<GoogleUserDataResponse> => {
  const res = await fetch(
    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      },
    },
  );
  const data = await res.json();
  return data;
};

export const setTokenAndRedirect = (token: string, redirectUrl: string) => {
  AuthStore.setToken(token);

  location.replace(redirectUrl);
};
