import Script from 'next/script';
import config from '@/src/config';

interface FreshDeskWidget {
  firstName?: string;
  lastName?: string;
  email?: string;
  exteranalId?: string;
}

const FreshDeskWidget = ({
  firstName,
  lastName,
  email,
  exteranalId,
}: FreshDeskWidget) => {
  return (
    <>
      {config().freshDeskChatToken && (
        <Script id="FreshDeskWidget">
          {`window.fcWidget.init({
      config: {
        cssNames: {
          widget: "custom_fc_frame"
        }
      },
      token: "${config().freshDeskChatToken}",
      host: "https://wchat.freshchat.com"
    });
    window.fcWidget.setExternalId("${exteranalId}");
    window.fcWidget.user.setFirstName("${firstName}");
    window.fcWidget.user.setLastName("${lastName}");
    window.fcWidget.user.setEmail("${email}");`}
        </Script>
      )}
    </>
  );
};

export default FreshDeskWidget;
