'use client';

import { useState, useEffect } from 'react';
import FreshDeskWidget from './FreshDeskWidget';

import { useAdminUserSearchParam } from '@/src/lib/hooks/use-admin-user';

export const SiteWideFreshDesk = () => {
  const [showWidget, setShowWidget] = useState(false);
  const { user } = useAdminUserSearchParam();
  useEffect(() => {
    setTimeout(() => {
      setShowWidget(true);
    }, 3000);
  }, []);
  return (
    <>
      {showWidget && (
        <FreshDeskWidget
          exteranalId={`${user?.['user-id']}`}
          email={user?.email}
          firstName={`${user?.['first-name']}`}
        />
      )}
    </>
  );
};
