import { facebookLogout } from '@/src/app/(auth)/actions';
import { googleLogout } from '@react-oauth/google';
import { use, useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { AuthService, current_user_result, UserService } from '../api/v3';
import { heapIdentify } from '../utils';

export type CurrentUserSubjectProps = {
  loading: boolean;
  user: current_user_result | undefined;
};

const initialState: CurrentUserSubjectProps = {
  loading: true,
  user: undefined,
};
let state: CurrentUserSubjectProps = initialState;

const subject = new BehaviorSubject<CurrentUserSubjectProps>(state);

const refreshCurrentUser = async () => {
  const { result: user } = await UserService.currentUser();

  // result object has data in it, regardless of if
  // the user is logged in or not
  // using this check to see if the user is actually logged in
  const isLoggedIn = user['user-id'];

  AuthStore.update(
    isLoggedIn ? { user, loading: false } : { user: undefined, loading: false },
  );
};

export const AuthStore = {
  // sets up initial state of user (undefined)
  init: () => subject.next(state),
  subscribe: (setState: any) => subject.subscribe(setState),
  refreshCurrentUser: () => refreshCurrentUser(),
  setToken: (token: string) => {
    localStorage.setItem('token', token);
  },
  logout: async () => {
    await AuthService.logout();
    await facebookLogout();
    await googleLogout();
    localStorage.removeItem('token');
    refreshCurrentUser();
    window.location.href = '/v4';
  },
  // TODO: add login, logout etc. here
  update: (payload: CurrentUserSubjectProps) => {
    const { user } = payload ?? {};
    //Setup heap user. User is going to be passed on each heap event.
    heapIdentify(user);
    state = payload;
    subject.next(state);
  },
  unsubscribe: () => subject.unsubscribe(),
  initialState,
};

export const useAuthHook = (): CurrentUserSubjectProps => {
  const [currentUser, setCurrentUser] =
    useState<CurrentUserSubjectProps>(state);

  useEffect(() => {
    const authStore$ = AuthStore.subscribe(setCurrentUser);
    AuthStore.init();

    return () => authStore$.unsubscribe();
  }, []);

  return currentUser;
};
