import { createContext, useContext } from 'react';
import { FeatureFlag } from '../../api/v4/client/models/BuilderFeatureFlag';
type FeatureFlagContextType = {
  featureFlags: FeatureFlag[];
  getFeatureFlag: (title: string) => boolean;
};
const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: [],
  getFeatureFlag: (title: string) => {
    return false;
  },
});

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export default FeatureFlagContext;
